"use strict";

var Snapshot = {
  target: null,
  snap: null,
  dialog: document.querySelector('#snapshotDialog'),
  closeBtn: document.querySelector('#snapshotClose'),
  snapshotButtons: document.querySelectorAll('.snapshot-btn'),
  video: document.querySelector('#camera-stream'),
  image: document.querySelector('#snap'),
  startCamera: document.querySelector('#start-camera'),
  controls: document.querySelector('.controls'),
  takePhotoBtn: document.querySelector('#take-photo'),
  deletePhotoBtn: document.querySelector('#delete-photo'),
  downloadPhotoBtn: document.querySelector('.photoButtonDownload'),
  errorMessage: document.querySelector('#error-message'),
  getCameraAccess: function getCameraAccess() {
    Snapshot.takePhotoBtn.classList.add("disabled");
    navigator.getMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    if (!navigator.getMedia) {
      this.displayErrorMessage("Your browser does not support web camera.");
      return false;
    } else {
      navigator.getMedia({
        video: true
      }, function (stream) {
        //Snapshot.video.src = window.URL.createObjectURL(stream);
        Snapshot.video.srcObject = stream;
        Snapshot.video.play();
        Snapshot.video.onplay = function () {
          Snapshot.showVideo();
        };
        $('#error-message').css({
          'display': 'none'
        });
      }, function (err) {
        Snapshot.displayErrorMessage(err);
      });
      return true;
    }
  },
  showDialog: function showDialog() {
    if (this.getCameraAccess()) {
      this.dialog.style.display = 'block';
    }
  },
  hideDialog: function hideDialog() {
    Snapshot.dialog.style.display = 'none';
  },
  showVideo: function showVideo() {
    Snapshot.takePhotoBtn.classList.remove("disabled");
    document.getElementById('spanshot-camera-access').style.display = 'none';
    this.video.classList.add("visible");
    this.controls.classList.add("visible");
  },
  init: function init() {
    if (this.closeBtn && this.startCamera && this.takePhotoBtn && this.downloadPhotoBtn && this.deletePhotoBtn) {
      this.closeBtn.addEventListener('click', function (e) {
        Snapshot.hideDialog();
        var tracks = Snapshot.video.srcObject.getTracks();
        tracks.forEach(function (track) {
          track.stop();
        });
      });
      for (var i = 0; i < this.snapshotButtons.length; i++) {
        this.snapshotButtons[i].addEventListener('click', function (e) {
          Snapshot.showDialog();
          Snapshot.target = this.getAttribute('data-target');
        });
      }
      this.startCamera.addEventListener("click", function (e) {
        e.preventDefault();
        Snapshot.video.play();
        Snapshot.showVideo();
      });
      this.takePhotoBtn.addEventListener("click", function (e) {
        e.preventDefault();
        Snapshot.takePhotoBtn.classList.add("disabled");
        Snapshot.snap = Snapshot.takeSnapshot();
        Snapshot.image.setAttribute('src', Snapshot.snap);
        Snapshot.image.classList.add("visible");
        Snapshot.deletePhotoBtn.classList.remove("disabled");
        Snapshot.downloadPhotoBtn.classList.remove("disabled");
        Snapshot.video.pause();
      });
      this.downloadPhotoBtn.addEventListener('click', function (e) {
        e.preventDefault();
        Snapshot.image.setAttribute('src', "");
        Snapshot.image.classList.remove("visible");
        Snapshot.deletePhotoBtn.classList.add("disabled");
        Snapshot.downloadPhotoBtn.classList.add("disabled");
        $.ajax({
          url: "/snapsaver",
          data: {
            'snap': Snapshot.snap
          },
          type: "POST",
          cache: false,
          dataType: "json",
          // Change this according to your response from the server.
          error: function error(err) {
            console.error(err);
          },
          success: function success(data) {
            console.log(data);
          },
          complete: function complete() {
            console.log("Request finished.");
          }
        }).done(function (response) {
          if (response.status) {
            var target = Snapshot.target;
            var control = $('#form-field-' + target + '-control');
            control.addClass('upload-success');
            control.removeClass('hidden upload-error');
            $('#form-field-isSnapshot').attr('value', '1');
            $('#form-field-' + target + '-preview').removeClass('hidden');
            $('#form-field-' + target + '-preview .file-preview-in').removeClass('hidden');
            $('#form-field-' + target + '-preview .on-preview').attr('src', Snapshot.snap);
            $('#form-field-' + target + '-preview .on-preview-ok').attr('src', '/assets/img/ok.svg');
            $('#form-field-' + target + '-preview .close-preview').attr('src', '/assets/img/x.svg');
            $('#form-field-' + target + '-holder-name').addClass('hidden');
            $('#form-field-' + target).addClass('hidden');
            $('#form-field-' + target + '-holder').attr('value', response.path);
            $('#form-field-' + target).removeAttr('data-required');
            validateField($('#form-field-' + target));
            saveApplicationInBase();
            var tracks = Snapshot.video.srcObject.getTracks();
            tracks.forEach(function (track) {
              track.stop();
            });
          } else {
            alert('Failed to upload photo.');
          }
          Snapshot.hideDialog();
        }).fail(function (response) {
          alert('Failed to upload photo.');
          var control = $('#' + Snapshot.target + '-control');
          control.removeClass('upload-success hidden');
          control.addClass('upload-error');
        });
      });
      this.deletePhotoBtn.addEventListener("click", function (e) {
        e.preventDefault();
        Snapshot.image.setAttribute('src', "");
        Snapshot.image.classList.remove("visible");
        Snapshot.deletePhotoBtn.classList.add("disabled");
        Snapshot.downloadPhotoBtn.classList.add("disabled");
        Snapshot.video.play();
      });
    }
  },
  takeSnapshot: function takeSnapshot() {
    var hidden_canvas = document.querySelector('canvas'),
      context = hidden_canvas.getContext('2d');
    var width = this.video.videoWidth,
      height = this.video.videoHeight;
    if (width && height) {
      hidden_canvas.width = width;
      hidden_canvas.height = height;
      context.drawImage(this.video, 0, 0, width, height);
      return hidden_canvas.toDataURL('image/png');
    }
  },
  displayErrorMessage: function displayErrorMessage(error_msg, error) {
    error = error || "";
    if (error) {
      console.log(error);
    }
    this.errorMessage.innerText = error_msg;
    this.hideDialog();
    this.errorMessage.classList.add("visible");
  }
};
Snapshot.init();