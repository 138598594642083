"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
// global formLocalStorageKey

var isRecoveryPage = window.location.href.indexOf('form-recovery') !== -1;
var formLocalStorageValidationDataKey = "_e_indiain_details";
var debugMode = false;
var clearLocalStorage = false;
var validateOn = true;
var isStorageUsing = true;
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
var ivshour;
var server_date = new Date();
var formSent = false;
$.ajax({
  method: 'GET',
  url: '/actual-date',
  success: function success(resp) {
    server_date = new Date(resp.date);
  },
  async: false
});
function getServerDate() {
  return new Date(server_date.valueOf());
}
function changeCalendarOnHoliday() {
  var fromDate,
    toDate,
    todayDate,
    fromDate = Date.parse("2019-11-15"),
    toDate = Date.parse("2019-11-17"),
    todayDate = Date.now();
  if (todayDate <= toDate && todayDate >= fromDate) {
    return true;
  }
  return false;
}
function isSupportForDateInput() {
  $('body').append('<input id="hiddenInput" type="date" value="text-value">');
  var isSupport = $('#hiddenInput').val() !== 'text-value';
  $('#hiddenInput').remove();
  return isSupport;
}
var showBasicDatePicker = supportsTouch;
if (supportsTouch) {
  if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent) || !isSupportForDateInput()) {
    showBasicDatePicker = false;
  }
}

// dates var
var ftoday = getServerDate();
var fmm = ftoday.getMonth() + 1; //January is 0!
var fyyyy = ftoday.getFullYear();
var fdayw = ftoday.getDay();
var adddays = 3;
if (fmm < 10) {
  fmm = '0' + fmm;
}
var futureyear = fyyyy + 5;
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (fun /*, thisp*/) {
    var len = this.length;
    if (typeof fun != "function") throw new TypeError();
    var thisp = arguments[1];
    for (var i = 0; i < len; i++) {
      if (i in this) fun.call(thisp, this[i], i, this);
    }
  };
}
function setCalendarTextArrow() {
  var textI18PrevButton = '';
  var textI18NextButton = '';
  if ($('span').hasClass('ui-icon-text-prev-temp')) {
    textI18PrevButton = $('span.ui-icon-text-prev-temp').html();
  } else {
    textI18PrevButton = translations['form date callendar button prev'];
    $('span.ui-icon-text-prev-temp').html(textI18PrevButton);
  }
  if ($('span').hasClass('ui-icon-text-next-temp')) {
    textI18NextButton = $('span.ui-icon-text-next-temp').html();
  } else {
    textI18NextButton = translations['form date callendar button next'];
    $('span.ui-icon-text-next-temp').html(textI18NextButton);
  }
  setTimeout(function () {
    if ($('#ui-datepicker-div .ui-datepicker-group').length > 1) {
      if (!$('a').hasClass('ui-icon-text-prev')) $($("#ui-datepicker-div .ui-datepicker-header")[0]).before("<a class='ui-icon-text-prev' data-event='click' data-handler='prev'>" + textI18PrevButton + "</a>");
      if (!$('a+').hasClass('ui-icon-text-next')) $($("#ui-datepicker-div .ui-datepicker-header")[1]).before("<a class='ui-icon-text-next' data-event='click' data-handler='next'>" + textI18NextButton + "</a>");
    } else {
      if (!$('a').hasClass('ui-icon-text-prev')) $("#ui-datepicker-div .ui-datepicker-header").before("<a class='ui-icon-text-prev' data-event='click' data-handler='prev'>" + textI18PrevButton + "</a>");
      if (!$('a+').hasClass('ui-icon-text-next')) $("#ui-datepicker-div .ui-datepicker-header").before("<a class='ui-icon-text-next' data-event='click' data-handler='next'>" + textI18NextButton + "</a>");
    }
  }, 100);
}
function doYouNeedTableNormalize() {
  var currentWidth = $(window).width();
  if (currentWidth < 744 || supportsTouch && currentWidth <= 1024) {
    $('.countries-table table td:empty').css('border-top', '0px');
  } else {
    $('.countries-table table td:empty').css('border-top', '');
  }
}
function setCalendarSettings() {
  $.ajax({
    url: 'getCalendar',
    method: "GET",
    async: false,
    success: function success(result) {
      result = JSON.parse(result);
      if (typeof result.settings.flightOffset !== 'undefined') {
        adddays = parseInt(result.settings.flightOffset);
      }
      setDateTypeField();
    },
    error: function error() {
      setDateTypeField();
    }
  });
}
function setDateTypeField() {
  if (document.getElementById('form-field-contact-phone')) document.getElementById('form-field-contact-phone').type = 'tel';
  var ftoday = getServerDate();
  var fmm = ftoday.getMonth() + 1; //January is 0!
  var fyyyy = ftoday.getFullYear();
  var fdayw = ftoday.getDay();
  if (fmm < 10) {
    fmm = '0' + fmm;
  }
  var futureyear = fyyyy + 5;
  if (showBasicDatePicker) {
    var minDate = new Date(ftoday.getTime() + adddays * 24 * 60 * 60 * 1000);
    var minDateMonth = minDate.getMonth() + 1;
    var minDateDay = minDate.getDate();
    if (minDateMonth < 10) {
      minDateMonth = '0' + minDateMonth;
    }
    if (minDateDay < 10) {
      minDateDay = '0' + minDateDay;
    }
    $('#form-field-date-of-arrival').attr({
      'type': 'date',
      'min': minDate.getFullYear() + '-' + minDateMonth + '-' + minDateDay,
      'max': futureyear + '-' + minDateMonth + '-' + minDateDay
    });
  } else {
    var minDateOut = new Date(ftoday.getTime() + adddays * 24 * 60 * 60 * 1000);
    $('#form-field-date-of-arrival').datepicker({
      minDate: minDateOut,
      maxDate: "+20y",
      dateFormat: "dd/mm/yy",
      timepicker: false,
      prevText: "",
      nextText: "",
      onChangeMonthYear: function onChangeMonthYear() {
        setCalendarTextArrow();
      },
      numberOfMonths: window.innerWidth > 760 ? 2 : 1
    }).bind('click keyup', function () {
      setCalendarTextArrow();
    });

    // Lock manual entry
    $(".disable-input input").attr('readonly', true);
  }
  window.insuranceEnabled && addInsuranceDatepicker();
}
$(function () {
  $('.form select').val('');
  try {
    localStorage.setItem('localStorage', 1);
    localStorage.removeItem('localStorage');
  } catch (e) {
    Storage.prototype._setItem = Storage.prototype.setItem;
    Storage.prototype.setItem = function () {};
    isStorageUsing = false;
  }

  // set calendar for mobile or desktop
  setCalendarSettings();

  // Load form
  if (isRecoveryPage) {
    loadForm($('#apply-form-data').data('form'));
  } else {
    loadForm(false);
  }

  //
  //  Main function for hiding/showing hidden fields
  //
  hiddenFieldController();
  invoiceHiddenForm();
  processForm();
  latinInputs();
  $('.with-hint').tooltip({
    position: {
      at: "center top"
    },
    tooltipClass: "hint-box",
    content: function content() {
      var el = $(this);
      var content = el.attr('title');
      return content;
    }
  });
  $('.ui-datepicker').addClass('notranslate');

  // Add readonly to inputs if parent container has class ".disable-input"
  // $(".disable-input input").attr('readonly', true);

  //
  // cookiesWarning
  //
  cookiesWarning();

  //
  //  Form label  equal height
  //
  formLabelsEqual();
  $(window).on('resize', function () {
    formLabelsEqual();
  });

  //
  // Terms read more
  //
  $('.terms-read-more').click(function (e) {
    var $terms = $('.accept-terms-long');
    $terms.toggleClass('full-text');
  });

  //
  // Card proccessing form
  //
  cardProcessingForm();

  //
  // Payment form rwd
  //
  var $paymentForm = $('#payment-form');
  var $colFirstName = $('.col-first-name');
  var $colLastName = $('.col-last-name');
  if ($paymentForm.length && $(window).width() <= 720) {
    $colLastName.insertAfter($colFirstName);
  }

  //
  // Passort number helper
  //
  //S8 TOTAL FUCKUP    passportNumberHelper();

  //
  // show application no if storage isset
  //
  if (!isRecoveryPage) {
    showApplicationNo();
  }
  $('#form input[type=checkbox]').on('change', function (e) {
    $(this).blur();
  });
});
function showApplicationNo() {
  var data = storageDataToObj();
  if (data !== undefined && data !== null && data.initializeData !== undefined) {
    var AppNumber = data.initializeData.application_no;
    var AppHash = data.initializeData.hash;
    if (typeof AppNumber !== "undefined" && AppNumber !== '' && typeof AppHash !== "undefined" && AppHash !== '') {
      $('.form-application-content-no .form-application-no').remove();
      $('.form-application-content-no').append($('<span class="form-application-no">').text(AppNumber), $('<span class="form-application-hash hidden">').text(AppHash)).removeClass('hidden');
    }
  }
}
function getFormDataAsJson() {
  var appNumber = $('.form-application-no').eq(0).text(),
    appHash = $('.form-application-hash').eq(0).text();
  return {
    initializeData: {
      application_no: appNumber,
      hash: appHash
    },
    fields: getFieldsForm()
  };
}
function latinInputs() {
  var $inputs = $(".latin-only, input.latin-only");

  // Disable pasting
  $inputs.on("paste", function (e) {
    e.preventDefault();
    return;
  });
  $inputs.keypress(function (e) {
    var key = e.keyCode ? e.keyCode : e.which;
    if (!(key >= 65 && key <= 90 || key >= 97 && key <= 122 || key == 32)) {
      e.preventDefault();
    }
  });
}
function invoiceHiddenForm() {
  var $formSelector = $('[name="form[invoice]"]');
  $formSelector.on('change', function () {
    var checked = $(this).prop('checked');
    if (checked) {
      $('.invoice-hidden-row').removeClass('hidden');
    } else {
      $('.invoice-hidden-row').addClass('hidden');
    }
    formLabelsEqual();
  }).trigger('change');
}
function hiddenFieldController() {
  // Shows text field for religion description, if user selected "other"
  $("#form-field-religion").change(function (e) {
    if ($(this).val() == "others") {
      $("#form-field-religion-description").parent().removeClass("hidden");
    } else {
      $("#form-field-religion-description").parent().addClass("hidden");
    }
  }).trigger('change');

  // Shows text field for visible identification marks description
  $('[name="form[identification-marks-question]"]').change(function (e) {
    var value = $('[name="form[identification-marks-question]"]:checked').val();
    if (value == 1) {
      $("#form-field-identification-marks-answer").parent().removeClass("hidden");
    } else {
      $("#form-field-identification-marks-answer").parent().addClass("hidden");
    }
  }).trigger('change');

  // Shows additional passport hidden fields
  $('[name="form[any-other-valid-passport-question]"]').change(function () {
    var value = $('[name="form[any-other-valid-passport-question]"]:checked').val();
    if (value == 1) {
      $(".additional-passport-fields").removeClass("hidden");
    } else {
      $(".additional-passport-fields").addClass("hidden");
    }
  }).trigger('change');

  // Shows spouse hidden fields
  $("#form-field-applicant-marital-status").change(function () {
    if ($(this).val() == "married") {
      $(".spouse-fields").removeClass("hidden");
    } else {
      $(".spouse-fields").addClass("hidden");
    }
  }).trigger('change');

  // Pakistan held area
  $('[name="form[pakistan-held-area-question]"]').change(function () {
    var value = $('[name="form[pakistan-held-area-question]"]:checked').val();
    if (value == 1) {
      $(".pakistan-held-area-answer").removeClass("hidden");
    } else {
      $(".pakistan-held-area-answer").addClass("hidden");
    }
  }).trigger('change');

  // Occupation other
  $('#form-field-employment-profession').change(function () {
    if ($(this).val() == "others") {
      $("#form-field-employment-profession-other").parent().removeClass("hidden");
    } else {
      $("#form-field-employment-profession-other").parent().addClass("hidden");
    }
  }).trigger('change');
  $("#form-field-employment-status").change(function () {
    var showOccupation = $(this).val() === 'employed';
    $("#form-field-employment-occupation-info").parent().toggleClass('hidden', !showOccupation);
  }).trigger('change');
}
function passportNumberHelper() {
  var $field = $('[name="form[passport-number]"], [name="check[passport_number]"]');
  var validChars = new RegExp("[a-zA-Z0-9]");
  var invalidChars = new RegExp("[oO]");
  $field.on('keypress', function (e) {
    var key = e.keyCode ? e.keyCode : e.which;
    var _char = String.fromCharCode(key);
    if (!validChars.test(_char) && key != 8) {
      e.preventDefault();
      return;
    }
  }).trigger('keypress');
  $field.on('keyup', function () {
    $field.val($field.val().toUpperCase());
  });
}
function cardProcessingForm() {
  var $form = $('#card-processing-form');
  var $btn = $form.find('[type="submit"]');
  var $message = $('.payment-status');
  $form.submit(function (e) {
    e.preventDefault();
    $btn.prop('disabled', true).addClass('loading');
    $message.html("").removeClass('show-error').removeClass('show-success');
    $.ajax({
      url: $form.attr('action'),
      method: "POST",
      dataType: "json",
      data: $form.serialize(),
      success: function success(response) {
        if (response.status == true) {
          window.onbeforeunload = null;
          window.location.href = $form.attr('data-success-redirect');
          $form.hide();
          $message.html(response.message).removeClass('show-error').addClass('show-success');
        } else {
          $message.html(response.message).removeClass('show-success').addClass('show-error');
        }
        $btn.prop('disabled', false).removeClass('loading');
      },
      error: function error(response) {
        console.log(response.responseText);
        $btn.prop('disabled', false).removeClass('loading');
      }
    });
  });
}
function formLabelsEqual() {
  if ($(window).width() <= 767) {
    return;
  }
  var maxHeight = 0;
  var currentHeight = 0;
  var $columns;
  $('#form .form-row-fields').each(function () {
    maxHeight = 0;
    $columns = $(this).find('.fields-column:visible:not(.clearfix):not(.noequalize)');
    $columns.each(function () {
      currentHeight = $(this).height();
      if (currentHeight > maxHeight) maxHeight = currentHeight;
    });
    if ($columns.length > 1) {
      $columns.each(function () {
        var $obj = $(this);
        currentHeight = $obj.height();
        if (currentHeight < maxHeight) {
          $obj.css('margin-top', maxHeight - currentHeight);
        } else {
          // reset value
          $obj.css('margin-top', '0');
        }
      });
    }
  });
}
function validActiveStep(currentSection, stepToActive) {
  invoiceHiddenForm();
  hiddenFieldController();
  var fields = currentSection.find('[data-required]');
  var focused = false;
  var validationDataInput = $('[name="form[validation-data]"]');

  // remove all errors befoure validate
  $('.with-error').removeClass('with-error');
  var fieldsLengths = fields.length;
  var out = {
    'pass': true,
    'item': {}
  };
  for (var i = 0; i < fieldsLengths; i++) {
    var $obj = $(fields[i]);

    // get fields from not having hidden class
    var visible = !$obj.parents().hasClass('hidden');
    if (visible) {
      var defaultValidateField = false;
      $obj.parents().map(function () {
        if (!validateOn) {
          var elemAttr = $(this).attr('data-always-validate');
          if (elemAttr === 'yes') defaultValidateField = true;
        }
      });
      if (!validateOn && !defaultValidateField) {
        $obj.addClass('with-success');
        continue;
      }
      var isValid = '';
      // match form name
      var regex = new RegExp(/^form\[(.*?)\]$/);
      var match = regex.exec($obj.attr('name'));
      var currentValidateData = validationDataInput.val();
      isValid = validateField($obj);
      // console.log(fields[i]);
      // console.log(isValid);
      if (!isValid) {
        if (!focused) {
          focused = true;
          out = {
            'pass': false,
            'item': $obj
          };
        }

        // break;
      } else if (currentValidateData.search(match[1]) == -1) {
        validationDataInput.val(validationDataInput.val() + match[1] + ";");
      }
    }
  }
  return out;
}
StepProcess();
function StepProcess() {
  $('[name="form[stepButton1]"]').on('click', function () {
    var currentStep = 1;
    $('[name="form[stepButton1]"]').removeAttr('data-required');
    if (setActiveStep({
      'currentStep': currentStep,
      'action': 'point',
      'animate': true
    }) == true) {
      $('.step-2').fadeIn("slow");
      $('.step-2').not('.invoice-hidden-row').removeClass('hidden');
      $('[name="form[stepButton1]"]').attr('disabled', true).fadeOut("fast");
      $('[name="form[stepButton1]"]').addClass('hidden');
      formLabelsEqual();
    } else {
      alert(translations['form field warning']);
    }
  });
  $('[name="form[stepButton2]"]').on('click', function () {
    var currentStep = 2;
    $('[name="form[stepButton2]"]').removeAttr('data-required');
    if (setActiveStep({
      'currentStep': currentStep,
      'action': 'point',
      'animate': true
    }) == true) {
      $('.step-3').fadeIn("slow");
      $('.step-3').removeClass('hidden');
      $('[name="form[stepButton2]"]').attr('disabled', true).fadeOut("fast");
      $('[name="form[stepButton2]"]').addClass('hidden');
      formLabelsEqual();
    } else {
      alert(translations['form field warning']);
    }
  });
  $('[name="form[stepButton3]"]').on('click', function () {
    var currentStep = 3;
    $('[name="form[stepButton3]"]').removeAttr('data-required');
    if (setActiveStep({
      'currentStep': currentStep,
      'action': 'point',
      'animate': true
    }) == true) {
      $('.step-4').fadeIn("slow");
      $('.step-4').removeClass('hidden');
      $('[name="form[stepButton3]"]').attr('disabled', true).fadeOut("fast");
      $('[name="form[stepButton3]"]').addClass('hidden');
      formLabelsEqual();
    } else {
      alert(translations['form field warning']);
    }
  });
}
showTermsForm();
function showTermsForm() {
  $('[name="form[type-of-visa]"]').change(function () {
    var visaType = this.value;
    if (visaType == 'single-30D') {
      $('.terms-long').find("#terms-text").html(translations['form label terms single 10']);
      $('.typeOfVisaUnderLabel').text(translations['type of visa under text single 30']);
    } else if (visaType == 'multiple-90D') {
      $('.terms-long').find("#terms-text").html(translations['form label terms single 30']);
      $('.typeOfVisaUnderLabel').text(translations['type of visa under text single 30']);
    }
  }).trigger('change');
}
function animateFormStep(stepToActive) {
  var duration = 700;
  $('.form-step-active').fadeOut(duration, function () {});
}
function setActiveStep(argsObj) {
  var stepsArray = [1, 2, 3];
  var stepToActive = 1;
  var stepValidData = {};
  var currentSection;
  var specifigSectionHasInvalidField = false;

  // auto change step
  if (argsObj.currentStep == 0) {
    for (var n in stepsArray) {
      currentSection = $('.step-' + stepsArray[n]);
      stepValidData = validActiveStep(currentSection, stepToActive);

      // if form section field has error

      if (!stepValidData.pass) {
        $('html, body').scrollTop(stepValidData.item.offset().top - 200);
        stepValidData.item.focus();
        break;
      }
      if (stepToActive < 4) stepToActive++;
    }
  } else {
    if (argsObj.action == 'prev') stepToActive = parseInt(argsObj.currentStep) - 1;
    if (argsObj.action == 'next') {
      stepToActive = parseInt(argsObj.currentStep) + 1;

      // check if curent step is valid
      currentSection = $('.step-' + argsObj.currentStep);
      stepValidData = validActiveStep(currentSection, argsObj.currentStep);

      // if form section field has error
      if (!stepValidData.pass) {
        // alert when not valid
        alert(translations["form field warning"]);
        $('html, body').scrollTop(stepValidData.item.offset().top - 250);
        stepValidData.item.focus();
        specifigSectionHasInvalidField = true;
      }
    }
    if (argsObj.action == 'point') {
      stepToActive = 1;
      for (var i = 1; i <= argsObj.currentStep; i++) {
        currentSection = $('.step-' + i);
        stepValidData = validActiveStep(currentSection, stepToActive);
        if (stepValidData.pass) {
          if (i == argsObj.currentStep) {
            return true;
          }
          if (stepToActive < 4) {
            stepToActive++;
          }
        } else {
          $('html, body').scrollTop(stepValidData.item.offset().top - 250);
          stepValidData.item.focus();
          break;
        }
      }
    }
  }
  if (specifigSectionHasInvalidField === false) {
    // animate step if nesseccery
    if (argsObj.animate == true) {
      animateFormStep(stepToActive);
    } else {
      $('.form-step-active').removeClass('form-step-active');
      $('.form-step-' + stepToActive).addClass('form-step-active');
      $('.progress-bar .step').removeClass('active');
      for (var i = 1; i <= stepToActive; i++) {
        $('.progress-bar .step:nth-child(' + i + ')').addClass('active');
      }
    }
  }
}
function loadForm(formData) {
  //prepare data
  if (formData !== false && _typeof(formData) === "object") {
    var retriveData = formData;
  } else {
    var retriveData = storageDataToObj();
    if (typeof retriveData === "undefined" || retriveData === "" || retriveData === null || $('#form').length <= 0) {
      $('.progress-bar .step:nth-child(1)').addClass('active');
      return;
    }
  }
  $('#form select').val('');

  // load form field data
  if (retriveData && retriveData.fields && retriveData.fields.form) {
    var formItems = Object.keys(retriveData.fields.form);
    for (var i = 0; i < formItems.length; i++) {
      var $field = $('[name="form[' + formItems[i] + ']"]');
      if ($field.attr('type') == "radio") {
        $('[name="form[' + formItems[i] + ']"][value="' + retriveData.fields.form[formItems[i]] + '"]').prop("checked", true).trigger('change');
      } else if ($field.attr('type') == "checkbox") {
        if (retriveData.fields.form[formItems[i]] != '') $('[name="form[' + formItems[i] + ']"][value="' + retriveData.fields.form[formItems[i]] + '"]').prop("checked", true).trigger('change');
      } else {
        // change format date
        if (['date-of-arrival'].concat(_toConsumableArray(insuranceDateFieldsArray)).indexOf(formItems[i]) !== -1) {
          var dateFieldSeparator = '.';
          if (!showBasicDatePicker) {
            dateFieldSeparator = '/';
          }

          /*
           * - date base format : rrrr-mm-dd
           * - date form format mobile: dd.mm.rrrr
           * - date form format desktop: dd/mm/rrrr
           *
           */

          var storageValidationDataToObj = checkValidityOfStorageValidationDataKey();
          if (!$.isEmptyObject(storageValidationDataToObj) && storageValidationDataToObj.fieldsStatuses.doa !== 'valid') {
            retriveData.fields.form[formItems[i]] = '';
          } else {
            var dateOfArrivalVal = retriveData.fields.form[formItems[i]];
            if (dateOfArrivalVal) {
              var dateOfArrivalValArr = dateOfArrivalVal.split('-');
              if (checkIfFlightDateIsValidDateValueBasedOnDeviceType(dateOfArrivalValArr, true)) {
                if (!showBasicDatePicker) {
                  dateOfArrivalVal = dateOfArrivalValArr[2] + dateFieldSeparator + dateOfArrivalValArr[1] + dateFieldSeparator + dateOfArrivalValArr[0];
                }
              } else {
                dateOfArrivalVal = '';
              }
              retriveData.fields.form[formItems[i]] = dateOfArrivalVal;
            } else {
              retriveData.fields.form[formItems[i]] = '';
            }
          }
        }
        $field.val(retriveData.fields.form[formItems[i]]).trigger('change');
      }
    }
  }

  // load step if application not completed
  setActiveStep({
    'currentStep': 0,
    'action': false,
    'animate': false
  });
}
function cookiesWarning() {
  var cookiesAccepted = getCookie("cookies") == "y";
  var $cookies = $('.cookie-warning');
  var $closeBtn = $('#cookie-close');
  if (!cookiesAccepted) $cookies.removeClass('hidden');
  $closeBtn.click(function (e) {
    e.preventDefault();
    setCookie("cookies", "y", 365);
    $cookies.addClass('hidden');
  });
}
function setCookie(cname, cvalue, exdays) {
  var d = getServerDate();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}
function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
function isValidDate(dateString) {
  //console.log('isValidDate()');
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;
  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || year % 100 != 0 && year % 4 == 0) monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}
;
function validateFieldsBefore($stopBefore, event) {
  $('#form input, #form select').each(function () {
    var $obj = $(this);
    if ($obj.attr('id') == $stopBefore.attr('id')) return false;else validateField($obj, event);
  });
}
function diffDates(dateObj) {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  return Math.round((dateObj.first.getTime() - dateObj.second.getTime()) / oneDay);
}
function getToodayDate() {
  var dateStart = getServerDate();
  var year = dateStart.getFullYear();
  var month = dateStart.getMonth();
  var day = dateStart.getDate();
  return new Date(year, month, day, 1, 0, 0, 0);
}
function checkIfFlightDateIsValidDateValueBasedOnDeviceType(dateValueSplitIntoParts, useCommonDateFormat) {
  if (!(dateValueSplitIntoParts.length === 3)) {
    return false;
  }
  if (!/^\d+$/.test(dateValueSplitIntoParts[0]) || !/^\d+$/.test(dateValueSplitIntoParts[1]) || !/^\d+$/.test(dateValueSplitIntoParts[2])) {
    return false;
  }
  var dayIndex = 0,
    monthIndex = 1,
    yearIndex = 2;
  if (showBasicDatePicker || typeof useCommonDateFormat !== 'undefined' && useCommonDateFormat) {
    dayIndex = 2;
    monthIndex = 1;
    yearIndex = 0;
  }
  if (dateValueSplitIntoParts[yearIndex].length === 4 && parseInt(dateValueSplitIntoParts[monthIndex], 10) >= 1 && parseInt(dateValueSplitIntoParts[monthIndex], 10) <= 12 && parseInt(dateValueSplitIntoParts[dayIndex], 10) >= 1 && parseInt(dateValueSplitIntoParts[dayIndex], 10) <= 31) {
    return true;
  }
  return false;
}
function validateField($field, event) {
  event = event || null;
  var isValid = true;
  var fieldType = $field.attr('type');
  var isRequired = $field.attr('data-required') !== undefined;
  if ($field.length === 0) {
    return false;
  }
  if (fieldType == "checkbox") {
    if ($field.prop('name') == 'form[invoice]') {
      isValid = true;
    } else if ($field.prop('checked') == false) {
      isValid = false;
    }
    $field = $field.parents('label');
  } else if (fieldType == "radio") {
    var v = $('[name="' + $field.attr('name') + '"]:checked').val();
    if (v == null || typeof v == "undefined") {
      isValid = false;
    }
    if ($field.closest('.radio-column').length > 0) {
      $field = $field.closest('.radio-column').find('label');
    } else if ($field.closest('.insurance-question').length > 0) {
      $field = $field.closest('.insurance-question').find('label');
    }
  } else {
    if ($field.val() == null || typeof $field.val() === "undefined") {
      isValid = false;
    } else {
      var f = $field.val();
      if (typeof f == "string" && f.trim() == "") isValid = false;
    }
  }
  if ($field.attr('id') == "form-field-contact-email-repeat") {
    if ($('#form-field-contact-email').val() !== $field.val()) isValid = false;
  }
  if ($field.attr('id') == "form-field-contact-email") {
    validateField($('#form-field-contact-email-repeat'));
  }
  if ($field.attr('id') == 'form-field-birth-day' || $field.attr('id') == 'form-field-birth-month' || $field.attr('id') == 'form-field-birth-year') {
    var selectedValues = {
      day: parseInt($('#form-field-birth-day option:selected').text()) || null,
      month: $('#form-field-birth-month option:selected').prevAll().length || null,
      year: parseInt($('#form-field-birth-year option:selected').text()) || null
    };
    if (selectedValues.day && selectedValues.month && selectedValues.year) {
      var selectedDate = new Date(selectedValues.year, selectedValues.month - 1, selectedValues.day);
      var selectedMonthLength = new Date(selectedValues.year, selectedValues.month, 0).getDate();
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      if (selectedMonthLength < selectedValues.day || selectedDate > today) {
        isValid = false;
        $('#form-field-birth-day').removeClass('with-success').addClass('with-error');
        $('#form-field-birth-month').removeClass('with-success').addClass('with-error');
        $('#form-field-birth-year').removeClass('with-success').addClass('with-error');
      } else {
        $('#form-field-birth-day').removeClass('with-error').addClass('with-success');
        $('#form-field-birth-month').removeClass('with-error').addClass('with-success');
        $('#form-field-birth-year').removeClass('with-error').addClass('with-success');
      }
    }
  }
  if (fieldType == "email") {
    var regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEx.test($field.val())) {
      isValid = false;
    }
  }

  // if(!$field.is(":visible") || $field.parent().hasClass('hidden-vs'))
  if ($field.parent().hasClass('hidden-vs') || !$field.is(':visible')) isValid = true;
  if ($field.hasClass('hasDatepicker') && $field.val() != "" && $field.val() != null) {
    isValid = isValidDate($field.val());
  }
  if (window.insuranceEnabled && !validateInsuranceFields($field)) {
    isValid = false;
  }
  if ($field.attr('id') == 'form-field-date-of-arrival' && $field.val() != "" && $field.val() != null && !showBasicDatePicker) {
    // input date
    var fieldDateValue = $('#' + $field.attr('id')).val();
    var fieldDateValueArray = fieldDateValue.split('/');
    if (!checkIfFlightDateIsValidDateValueBasedOnDeviceType(fieldDateValueArray)) {
      isValid = false;
      validateOn = true;
    } else {
      var fieldDateMonthValue = fieldDateValueArray[1] - 1;
      var fieldDate = new Date(fieldDateValueArray[2], fieldDateMonthValue, fieldDateValueArray[0], 1, 0, 0, 0);

      //today
      var ftoday = getServerDate();
      var fdayw = ftoday.getDay();
      var currentDate = getToodayDate(); //today

      var diffDays = diffDates({
        first: fieldDate,
        second: currentDate
      });
      if (diffDays >= adddays) {
        isValid = true;
      } else {
        isValid = false;
      }

      // OUTDATED
      if ((changeCalendarOnHoliday() == 15 || changeCalendarOnHoliday() == 16) && fieldDate >= new Date(2019, 5, changeCalendarOnHoliday() + 5)) {
        isValid = true;
      } else if ((changeCalendarOnHoliday() == 15 || changeCalendarOnHoliday() == 16) && fieldDate < new Date(2019, 5, changeCalendarOnHoliday() + 5)) {
        isValid = false;
      }

      // OUTDATED
      if (changeCalendarOnHoliday() == 17 && fieldDate >= new Date(2019, 5, changeCalendarOnHoliday() + 4)) {
        isValid = true;
      } else if (changeCalendarOnHoliday() == 17 && fieldDate < new Date(2019, 5, changeCalendarOnHoliday() + 4)) {
        isValid = false;
      }
      if (diffDays > 3) {
        validateOn = false;
      } else {
        validateOn = true;
      }
      if (fieldDate.getYear() < currentDate.getYear()) {
        isValid = false;
      }
    }
  } else if ($field.attr('id') == 'form-field-date-of-arrival' && $field.val() != "" && $field.val() != null && showBasicDatePicker) {
    var arrivalField = $('#form-field-date-of-arrival');
    var arrivalFieldVal = arrivalField.val();
    var dateOfArrivalToParts = arrivalFieldVal.split('-');
    if (!checkIfFlightDateIsValidDateValueBasedOnDeviceType(dateOfArrivalToParts)) {
      isValid = false;
      validateOn = true;
    } else {
      var arrivalFieldValMin = arrivalField.attr('min');
      var arrivalFieldValMax = arrivalField.attr('max');
      var currentDate = getToodayDate(); //today

      var dVal = new Date(arrivalFieldVal);
      var dMin = new Date(arrivalFieldValMin);
      var dMax = new Date(arrivalFieldValMax);
      var diffDays = diffDates({
        first: dVal,
        second: currentDate
      });
      if (diffDays > 3) {
        validateOn = false;
      } else {
        validateOn = true;
      }
      if (dMin.getTime() > dVal.getTime() || dMax.getTime() < dVal.getTime()) {
        isValid = false;
      } else {
        isValid = true;
      }

      // OUTDATED
      if ((changeCalendarOnHoliday() == 15 || changeCalendarOnHoliday() == 16) && fieldDate >= new Date(2019, 5, changeCalendarOnHoliday() + 5)) {
        isValid = true;
      } else if ((changeCalendarOnHoliday() == 15 || changeCalendarOnHoliday() == 16) && fieldDate < new Date(2019, 5, changeCalendarOnHoliday() + 5)) {
        isValid = false;
      }

      // OUTDATED
      if (changeCalendarOnHoliday() == 17 && fieldDate >= new Date(2019, 5, changeCalendarOnHoliday() + 4)) {
        isValid = true;
      } else if (changeCalendarOnHoliday() == 17 && fieldDate < new Date(2019, 5, changeCalendarOnHoliday() + 4)) {
        isValid = false;
      }
    }
  }
  if ($field.find('input').attr('name') !== 'form[travel-insurance-answer]') {
    if ($field.attr('id') == 'form-field-date-of-arrival' && isStorageUsing) {
      var doaStatus = 'undefined';
      if ($field.val() !== "" && $field.val() !== null) {
        doaStatus = isValid ? 'valid' : 'invalid';
      }
      var storageValidationData = {
        fieldsStatuses: {
          doa: doaStatus
        }
      };
      localStorage.setItem(formLocalStorageValidationDataKey, JSON.stringify(storageValidationData));
    }
    if (isValid) {
      if (($field.is('select') || $field.attr('id') === 'form-field-travel-insurance-start-date' || $field.attr('id') === 'form-field-travel-insurance-end-date') && $field.val() == null) {
        $field.removeClass('with-error').removeClass('with-success');
      } else if ($field.is('label')) {
        $field.removeClass('with-error').addClass('with-success');
      } else {
        $field.removeClass('with-error').addClass('with-success');
      }
      var errorText = $field.closest('.fields-column').find('.form-field-error');
      if (errorText) {
        errorText.text('').addClass('hidden');
      }
    } else {
      if (isRequired || $field.val() != "" && $field.val() != null) {
        $field.removeClass('with-success').addClass('with-error');
      } else {
        $field.removeClass('with-error').addClass('with-success');
      }
    }
  }

  // add or remove field from "validation-data"
  var validationData = $('#form-field-validation-data').val();
  var incomingFieldName = $field.attr('name');
  if (fieldType == "radio" || fieldType == "checkbox") {
    incomingFieldName = $($field.find('input')[0]).attr('name');
  }
  incomingFieldName = incomingFieldName.replace('form[', '');
  incomingFieldName = incomingFieldName.replace(']', '');
  if (validationData != undefined) {
    validationData = validationData.substring(0, validationData.length - 1);
    validationData = validationData.split(';');
    if (validationData[0] === '') {
      validationData.pop();
    }
    var incomingFieldNameArrayIndex = validationData.indexOf(incomingFieldName);
    if (isValid && incomingFieldNameArrayIndex === -1) {
      validationData.push(incomingFieldName);
    }
    if (!isValid && incomingFieldNameArrayIndex >= 0) {
      validationData.splice(incomingFieldNameArrayIndex, 1);
    }
  }
  validationData = validationData.join(';');
  $('#form-field-validation-data').val(validationData + ';');
  return isValid;
}
function checkValidityOfStorageValidationDataKey() {
  var result = {};
  try {
    var storageValidationDataToObj = JSON.parse(localStorage.getItem(formLocalStorageValidationDataKey));
    if (storageValidationDataToObj.fieldsStatuses.doa === '' && ['valid', 'invalid', 'undefined'].indexOf(storageValidationDataToObj.fieldsStatuses.doa) === -1) {
      throw 'Invalid structure of storageValidationData!';
    }
    result = storageValidationDataToObj;
  } catch (e) {}
  return result;
}

// get all key->val array from form
function getFieldsForm() {
  var out = {};
  var $form = $('#form');
  var $fields = $form.find('input, select, textarea');
  $fields.each(function () {
    var type = $(this).attr('type');
    var name = $(this).attr('name');
    var value = $(this).val();
    name = name.replace('form[', '');
    name = name.replace(']', '');
    switch (type) {
      case 'radio':
        {
          var isChecked = $(this).is(':checked');
          if (isChecked) {
            out[name] = value;
          } else if (out[name] === undefined) {
            out[name] = '';
          }
          break;
        }
      case 'checkbox':
        {
          var isChecked = $(this).is(':checked');
          if (isChecked) {
            out[name] = value;
          } else if (out[name] === undefined) {
            out[name] = '';
          }
          break;
        }
      default:
        {
          if (['date-of-arrival'].concat(_toConsumableArray(insuranceDateFieldsArray)).indexOf(name) !== -1) {
            if (!showBasicDatePicker && value.length > 0) {
              var dateSep = '-';
              var valueArr = value.split('/');
              if (checkIfFlightDateIsValidDateValueBasedOnDeviceType(valueArr)) {
                value = valueArr[2] + dateSep + valueArr[1] + dateSep + valueArr[0];
              }
            }
          }
          out[name] = value;
          break;
        }
    }
  });
  return JSON.stringify({
    'form': out
  });
}

// initialize application in base
function initializeApplicationInBase(dataObj) {
  var dataTypeObj = {
    type: debugMode === true ? "html" : "json"
  };
  $.ajax({
    url: 'form-apply-eta-initialize',
    dataType: dataTypeObj.type,
    method: "POST",
    async: false,
    data: {
      formData: dataObj
    },
    success: function success(response) {
      if (response.status == false) {
        console.log('error php');
        console.log(response.message);
      } else {
        // check debug mode
        if (debugMode === true) {
          console.log('debug mode initializeApplicationInBase');
          console.log(response);
        } else {
          // set localStorage
          if (typeof Storage !== "undefined") {
            var r_m = response.message.orderData;
            var outObj = {
              initializeData: {
                hash: r_m.hash,
                application_no: r_m.id_str
              },
              fields: dataObj
            };
            var outData = JSON.stringify(outObj);
            if (isStorageUsing) {
              localStorage.setItem(formLocalStorageKey, outData);
              localStorage.setItem(formLocalStorageKey + '_date', Date.now());
            }
            // Show application number
            showApplicationNo();
          }
        }
      }
    },
    error: function error(response) {
      console.error('error ajax');
      console.error(response);
    }
  });
}

// save all fields to base if storage is initialize else
function saveApplicationInBase(formField) {
  if (formSent) {
    return;
  }
  var dataTypeObj = {
    type: debugMode === true ? "html" : "json"
  };
  if (!basicFieldsAreValid()) {
    return false;
  }
  var storageObj = isRecoveryPage ? getFormDataAsJson() : formIsInitialize();
  if (storageObj) {
    var dataObj = JSON.parse(getFieldsForm());
    // update base & storage

    var fieldToUpdate = typeof formField !== 'undefined' && typeof formField.attr('name') !== 'undefined' ? formField.attr('name') : '';
    $.ajax({
      url: 'form-apply-eta-field',
      dataType: dataTypeObj.type,
      method: "POST",
      data: {
        formData: dataObj,
        storageData: storageObj,
        fieldName: fieldToUpdate
      },
      success: function success(response) {
        if (response.status == false) {
          console.log('error php');
          console.log(response.message);
        } else {
          // check debug mode
          if (debugMode === true) {
            console.log(response);
          } else {
            if (response.message != 1) {
              console.log('data was not update');
            }

            // set localStorage + show application no
            if (!isRecoveryPage) {
              try {
                var dataInStorage = storageDataToObj();
                var outObj = {
                  initializeData: {
                    hash: dataInStorage.initializeData.hash,
                    application_no: dataInStorage.initializeData.application_no
                  },
                  fields: dataObj
                };
                var outData = JSON.stringify(outObj);
                if (isStorageUsing) {
                  localStorage.setItem(formLocalStorageKey, outData);
                  localStorage.setItem(formLocalStorageKey + '_date', Date.now());
                }
              } catch (e) {
                console.log('invalid json');
              }
            }
          }
        }
      },
      error: function error(response) {
        console.error('error ajax');
        console.error(response);
      }
    });
  }
}
function initializeApplication() {
  if (basicFieldsAreValid() && !formIsInitialize()) {
    //generate data to save
    var dataObj = getFieldsForm();
    initializeApplicationInBase(dataObj);
  }
}
function basicFieldsAreValid() {
  var mailField = $('#form-field-contact-email'),
    firstFieldValue = mailField.val(),
    secondFieldValue = $('#form-field-contact-email-repeat').val(),
    birthDDField = $('#form-field-birth-day'),
    birthMMField = $('#form-field-birth-month'),
    birthYYField = $('#form-field-birth-year');
  var notEmpty = firstFieldValue !== '' && secondFieldValue !== '' && birthDDField.val() !== null && birthMMField.val() !== null && birthYYField.val() !== null;
  if (notEmpty) {
    var isValid = firstFieldValue === secondFieldValue && validateField(mailField) && validateField(birthDDField) && validateField(birthMMField) && validateField(birthYYField);
    return isValid;
  } else {
    return false;
  }
}
function formIsInitialize() {
  if (clearLocalStorage && isStorageUsing) {
    localStorage.removeItem(formLocalStorageKey);
    localStorage.removeItem(formLocalStorageValidationDataKey);
  }
  var arrayStorageData = storageDataToObj();
  var status = arrayStorageData !== undefined ? arrayStorageData : false;
  return status;
}
function storageDataToObj() {
  if (typeof Storage === "undefined") return;
  if (isStorageUsing) {
    var data = localStorage.getItem(formLocalStorageKey);
  }
  if (typeof data === "undefined" || data === "" || data === null) return;
  return JSON.parse(data);
}
function validateBefoureSubmit() {
  var $form = $('#form');
  var $fields = $form.find('[data-required]');
  var output = true;

  // check all required fields
  for (var i = 0; i < $fields.length; i++) {
    var $obj = $($fields[i]);
    // remove from validate hidden fields
    var visible = !$obj.parents().hasClass('hidden');
    if (visible) {
      var defaultValidateField = false;
      $obj.parents().map(function () {
        if (!validateOn) {
          var elemAttr = $(this).attr('data-always-validate');
          if (elemAttr === 'yes') defaultValidateField = true;
        }
      });
      if (!validateOn && !defaultValidateField) continue;

      // match form name
      var regex = new RegExp(/^form\[(.*?)\]$/);
      var match = regex.exec($obj.attr('name'));
      var isValid = validateField($obj);
      if (!isValid && output === true) {
        $('html, body').scrollTop($obj.offset().top - 250);
        $obj.focus();
        output = false;
      }
    }
  }
  return output;
}
function processForm() {
  $('#form').submit(function (e) {
    var _this = this;
    e.preventDefault();
    $('.with-error').filter(function () {
      return $(_this).find('[name="form[terms]"]').length === 0;
    }).removeClass('with-error');
    var valid = validateBefoureSubmit();
    if (!document.querySelector('#form').checkValidity()) {
      return;
    }
    if (valid) {
      var $form = $('#form');
      if ($('.with-error').length > 0) {
        $('.with-error').removeClass('with-error');
      }
      var $submitBtn = $('#submitform');
      var data = isRecoveryPage ? getFormDataAsJson() : storageDataToObj();
      var uploadFields = $('.upload-documents div.form-row-fields:nth-child(1):not(.hidden)').find('input[type="hidden"]');
      var missingDocuments = '';
      if (uploadFields.length > 0) {
        uploadFields.each(function () {
          missingDocuments += $(this).attr('name').replace('form[', '').replace(']', '') + ';';
        });
        missingDocuments.slice(0, -1);
      }

      // set end of proccess
      $submitBtn.prop('disabled', true).addClass('loading');
      formSent = true;

      // form-apply-eta
      $.ajax({
        url: $form.attr('action'),
        dataType: "json",
        method: "POST",
        data: {
          hash: data.initializeData.hash,
          testMode: 0,
          missingDocuments: missingDocuments
        },
        success: function success(response) {
          if (response.status === false) {
            formSent = false;
            alert(formErrorMessage);
            console.log(response.msg);
          } else {
            //  if form fields valid clear storage & go to info page
            if (isStorageUsing) {
              localStorage.removeItem(formLocalStorageKey);
              localStorage.removeItem(formLocalStorageValidationDataKey);
            }
            window.onbeforeunload = null;
            window.location = response.redirectURL;
          }
          $submitBtn.prop('disabled', false).removeClass('loading');
        },
        error: function error(response) {
          alert(formErrorMessage);
          $submitBtn.prop('disabled', false).removeClass('loading');
          formSent = false;
        }
      });
    } else {
      alert(translations['form field warning']);
    }
  });
  $('#form button[type=submit]').click(function (e) {
    if (document.querySelector('#form input[name="form[terms]"]').checkValidity()) {
      $('input[name="form[terms]"]').closest('label').removeClass('with-error');
    } else {
      $('input[name="form[terms]"]').closest('label').addClass('with-error');
      if ($(window).scrollTop() > $('input[name="form[terms]"]').offset().top - 120) {
        $('html, body').animate({
          scrollTop: $('input[name="form[terms]"]').offset().top - 120
        }, 300, function () {
          if (document.querySelector('input[name="form[terms]"]').reportValidity) {
            document.querySelector('input[name="form[terms]"]').reportValidity();
          }
        });
      }
    }
    if (!validateBefoureSubmit()) {
      e.preventDefault();
    }
  });

  // prevent to past text to email repeat field
  $('#form-field-contact-email-repeat').on('paste', function (e) {
    e.preventDefault();
  });

  // check email fields and initialize if nessery
  if (!isRecoveryPage) {
    $('#form-field-contact-email-repeat, #form-field-contact-email, #form-field-birth-day, #form-field-birth-month, #form-field-birth-year').on('change', function (e) {
      initializeApplication();
    });
  }

  //////
  // update field in base and localStorage if email and application is initialize
  //////

  $('#form input, #form textarea').not('input[type="radio"]').on('focusout', function (e) {
    var item = $(this);
    if (item.attr('id') == 'form-field-date-of-arrival') {
      setTimeout(function () {
        if (validateField(item, e)) {
          saveApplicationInBase(item);
        }
      }, 200);
    } else {
      validateField($(this), e);
      saveApplicationInBase(item);
    }
  });
  $('form input[type=radio]').on('change', function (e) {
    validateField($(this), e);
    saveApplicationInBase();
  });
  $('#form select, #form #form-field-date-of-arrival').on('change', function (e) {
    validateField($(this), e);
    saveApplicationInBase($(this));
  });

  // set prev step
  $('.step-back-btn').on('click', function (e) {
    // set scroll on top
    window.scroll(0, 0);
    e.preventDefault();
    var currentStep = $(this).attr('data-step-no');
    setActiveStep({
      'currentStep': currentStep,
      'action': 'prev',
      'animate': true
    });
  });

  // set next step
  $('#form .step-btn').click(function (e) {
    // set scroll on top
    window.scroll(0, 0);
    e.preventDefault();
    var currentStep = $(this).attr('data-step-no');
    setActiveStep({
      'currentStep': currentStep,
      'action': 'next',
      'animate': true
    });
  });
  $('.progress-bar .point').on('click', function (e) {
    window.scroll(0, 0);
    e.preventDefault();
    var currentStep = $(this).attr('data-step-no');
    setActiveStep({
      'currentStep': currentStep,
      'action': 'point',
      'animate': true
    });
  });
}
browserIsIE();
//snapshot check if browser is IE or EDGE
function browserIsIE() {
  // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
  // var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  // Firefox 1.0+
  //    var isFirefox = typeof InstallTrigger !== 'undefined';
  // Safari 3.0+
  var isSafari = /constructor/i.test(window.HTMLElement) || function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  }(!window['safari'] || safari.pushNotification);
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;
  // Edge 20+
  //      var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1+
  //     var isChrome = !!window.chrome && !!window.chrome.webstore;
  // Blink engine detection
  //     var isBlink = (isChrome || isOpera) && !!window.CSS;
  var dualMonitors = window.screen.availLeft;
  if (isIE === true || isSafari === true || dualMonitors !== 0) {
    $('.photo-btn-wrapper').addClass('hidden');
    $('.snapshot-text').addClass('hidden');
  }
}
$(function () {
  $('input[readonly]').on('focus', function (ev) {
    $(this).trigger('blur');
  });
});
cvvInfo();
function cvvInfo() {
  var circle = $('.green-hint');
  var infoBox = $('.cvv-information');
  if (window.innerWidth <= 768) {
    circle.on('click', function (event) {
      infoBox.removeClass('hidden');
    });
  } else {
    circle.on('mouseover', function (event) {
      infoBox.removeClass('hidden');
    });
  }
  circle.on('mouseout', function (event) {
    infoBox.addClass('hidden');
  });
}
function showError(target, event, message) {
  event.preventDefault();
  var errorText = target.parentNode.querySelector('.form-field-error');
  target.classList.remove('with-success');
  target.classList.add('with-error');
  errorText.classList.remove('hidden');
  errorText.innerText = message;
}
$.fn.preventNonLatin = function () {
  /*
  CYRYLIC:
      u0400 - u052F
      u1C80 - u1C88
      u2DE0 - u2DFF
      uA640 - uA69F
  ARABIC SIGNS:
      u0600 - u077F
      u08A0 - u08BD
      u08D3 - u08FF
  CHINESE SIGNS:
      u2E80 - u2E99
      u2E9B - u2EF3
      u2F00 - u2FD5
      u3000 - u303F
      u3105 - u312F
      u3131 - u318E
      u3190 - u31BA
      u31C0 - u31E3
      u3200 - u321E
      u3220 - u32FE
      u3300 - u9FFF
      uA840 - uA877
      uF900 - uFA6D
      uFA70 - uFAD9
  */
  var nonLatinPattern = /[\u0400-\u052f\u1C80-\u1C88\u2DE0-\u2DFF\uA640-\uA69F\u0600-\u077F\u08A0-\u08BD\u08D3-\u08FF\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3000-\u303F\u3105-\u312F\u3131-\u318E\u3190-\u31BA\u31C0-\u31E3\u3200-\u321E\u3220-\u32FE\u3300-\u9FFF\uA840-\uA877\uF900-\uFA6D\uFA70-\uFAD9]/;
  this.keypress(function (e) {
    if (e.key.replace(nonLatinPattern, '') == '') {
      showError(this, e, translations['form label nonlatin error']);
    }
  });
  this.on('paste', function (e) {
    var pasteData;
    if (window.clipboardData !== undefined && window.clipboardData.getData !== undefined) {
      // IE
      pasteData = window.clipboardData.getData('text');
    } else if (e.originalEvent.clipboardData !== undefined && e.originalEvent.clipboardData.getData !== undefined) {
      pasteData = e.originalEvent.clipboardData.getData('text');
    }
    if (pasteData.match(nonLatinPattern)) {
      showError(this, e, translations['form label nonlatin error']);
    }
  });
};
$('#form-field-name').preventNonLatin();
$('#form-field-surname').preventNonLatin();
var getPrices = function getPrices(resolve) {
  var req = new XMLHttpRequest();
  var formdata = new FormData();
  $('input[name="form[nationality]"]').val() !== null && formdata.append('nationality', $('[name="form[nationality]"]').val());
  var storageData = storageDataToObj();
  storageData && storageData.initializeData && formdata.append('idStr', storageData.initializeData.application_no);
  formdata.append('currency', 'EUR');
  req.open('POST', '/prices', true);
  req.onreadystatechange = function () {
    if (req.readyState === 4) {
      if (req.status === 200) {
        resolve(JSON.parse(req.responseText));
      } else {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/logger.php', true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.send("message=CANNOT GET PRICES");
      }
    }
  };
  req.send(formdata);
};